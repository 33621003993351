import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import DropDown from "../Assets/DropDown.svg";
import DropDownUp from "../Assets/DropDownUp.svg";
import Points from "../Assets/points.svg";

const menuItems = [
  {
    name: "Autotron",
    path: "/autotron",
    type: "internal"
  },
  {
    name: "Certificate Validation",
    path: "/CertficateValidation",
    type: "internal",
    dropdown: true,
    subItems: [
      {
        name: "File Validation with Pensieve Integration",
        type: "internal",
        dropdown: true,
        subItems: [
          {
            name: "DashBoard",
            path: "https://grafana.maersk.io/d/rhoK5d5Vz/sre-model-dashboards-sre-certificate-expiry-model-dashboard?orgId=1&refresh=1m",
            type: "external"
          },
          {
            name: "Validation Script",
            path: "https://github.com/Maersk-Global/SRE-cert-check-script-telegraf",
            type: "external"
          }
        ]
      },
      {
        name: "Validation for URLs",
        path: "/ValidationForUrls",
        type: "internal",
        dropdown: false,
        outside: true
      }
    ]
  },
  {
    name: "Forensic",
    path: "/forensic",
    type: "internal",
    dropdown: false
  },
  {
    name: "iPACE",
    path: process.env.REACT_APP_LOADSTAR,
    type: "external"
  },
  {
    name: "Postmortem",
    path: process.env.REACT_APP_POSTMORTEM,
    type: "external"
  },
  {
    name: "SmartMute",
    path: process.env.REACT_APP_SMART_MUTE,
    type: "external"
  },
  {
    name: "CMDB Portal",
    path: process.env.REACT_APP_CMDB_PORTAL,
    type: "external"
  },
  {
    name: "Runbooks and Templates",
    path: process.env.REACT_APP_SRE_SKYDECK_LIBRARY_SITE,
    type: "external"
  },
  {
    name: "SLO as a Service",
    path: "/slodashboarding",
    type: "internal"
  },
  {
    name: "Status Page",
    path: process.env.REACT_APP_STATUS_PAGE,
    type: "external"
  },
  {
    name: "Daac",
    path: process.env.REACT_APP_DAAC,
    type: "external"
  },
  {
    name: "SLO-Driven Canary Deployment",
    path: process.env.REACT_APP_SLO_DRIVEN_CANARY_DEPLOYMENT,
    type: "external"
  }
];

function SideBar() {
  const location = useLocation();
  const [currPath, setCurrPath] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openSubDropdown, setOpenSubDropdown] = useState(null);

  useEffect(() => {
    setCurrPath(location.pathname);
  }, [location.pathname]);

  const handleDropdownToggle = (path) => {
    if (openDropdown === path) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(path);
      setOpenSubDropdown(null);
    }
  };

  const handleSubDropdownToggle = (path) => {
    if (openSubDropdown === path) {
      setOpenSubDropdown(null);
    } else {
      setOpenSubDropdown(path);
    }
  };

  return (
    <div className="home-menu-wrapper">
      <div className="homeMenu">
        {menuItems.map((item, index) => {
          if (item.dropdown) {
            return (
              <React.Fragment key={index}>
                <Link className="link" to={item.path}>
                  <div
                    className="menu-item extra_class"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      backgroundColor: currPath === item.path || openDropdown === item.path ? "#91D8F2" : "",
                      color: currPath === item.path || openDropdown === item.path ? "black" : ""
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDropdownToggle(item.path);
                    }}
                  >
                    <img src={Points} style={{ width: "16px" }} alt="points" />
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <p style={{ fontSize: "18px", marginLeft: "10px" }}>{item.name}</p>
                      {openDropdown !== item.path && <img src={DropDown} style={{ width: "20px" }} alt="Drop-Down-logo" />}
                      {openDropdown === item.path && <img src={DropDownUp} style={{ width: "20px" }} alt="Drop-Down-logo" />}
                    </div>
                  </div>
                </Link>
                {openDropdown === item.path && item.subItems.map((subItem, subIndex) => (
                  <React.Fragment key={subIndex}>
                    {subItem.dropdown ? (
                      <React.Fragment>
                        <div
                          className="menu-item inner_child"
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            backgroundColor: openSubDropdown === subItem.name ? "#91D8F2" : "",
                            color: openSubDropdown === subItem.name ? "black" : "",
                            borderBottom: "1px solid #005487"
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubDropdownToggle(subItem.name);
                          }}
                        >
                          <img src={Points} style={{ width: "20px", marginLeft: "20px" }} alt="points" />
                          <p style={{ fontSize: "16px", marginLeft: "15px" }}>{subItem.name}</p>
                          {openSubDropdown === subItem.name && <img src={DropDownUp} style={{ width: "20px" }} alt="Drop-Down-logo" />}
                          {openSubDropdown !== subItem.name && <img src={DropDown} style={{ width: "20px" }} alt="Drop-Down-logo" />}
                        </div>
                        {openSubDropdown === subItem.name && subItem.subItems.map((subSubItem, subSubIndex) => (
                          <a className="link" href={subSubItem.path} target="_blank" rel="noopener noreferrer" key={subSubIndex}>
                            <div
                              className="menu-item inner_child"
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                backgroundColor: currPath === subSubItem.path ? "#91D8F2" : "",
                                color: currPath === subSubItem.path ? "black" : "",
                                borderBottom: "1px solid #005487"
                              }}
                            >
                              <img src={Points} style={{ width: "20px", marginLeft: "20px" }} alt="points" />
                              <p style={{ fontSize: "12px", marginLeft: "15px" }}>{subSubItem.name}</p>
                            </div>
                          </a>
                        ))}
                      </React.Fragment>
                    ) : (
                      <a className="link" href={subItem.path} target="_blank" rel="noopener noreferrer" key={subIndex}>
                        <div
                          className="menu-item inner_child"
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            backgroundColor: currPath === subItem.path ? "#91D8F2" : "",
                            color: currPath === subItem.path ? "black" : "",
                            borderBottom: "1px solid #005487"
                          }}
                        >
                          <img src={Points} style={{ width: "20px", marginLeft: "20px" }} alt="points" />
                          <p style={{ fontSize: "16px", marginLeft: "15px" }}>{subItem.name}</p>
                        </div>
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          } else {
            return item.type === "internal" ? (
              <Link className="link" to={item.path} key={index}>
                <div
                  className="menu-item"
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    backgroundColor: currPath === item.path ? "#91D8F2" : "",
                    color: currPath === item.path ? "black" : ""
                  }}
                  onClick={() => {
                    setOpenDropdown(null);
                    setOpenSubDropdown(null);
                  }}
                >
                  <img src={Points} style={{ width: "16px" }} alt="points" />
                  <p style={{ fontSize: "18px", marginLeft: "10px" }}>{item.name}</p>
                </div>
              </Link>
            ) : (
              <a className="link" href={item.path} target="_blank" rel="noopener noreferrer" key={index}>
                <div
                  className="menu-item"
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    backgroundColor: currPath === item.path ? "#91D8F2" : "",
                    color: currPath === item.path ? "black" : ""
                  }}
                  onClick={() => {
                    setOpenDropdown(null);
                    setOpenSubDropdown(null);
                  }}
                >
                  <img src={Points} style={{ width: "16px" }} alt="points" />
                  <p style={{ fontSize: "18px", marginLeft: "10px" }}>{item.name}</p>
                </div>
              </a>
            );
          }
        })}
      </div>
    </div>
  );
}

export default SideBar;
